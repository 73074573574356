import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import configs from '../configs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { print } from '../utils/print_minimal';
import { Search as SearchIcon } from 'react-feather';
import {
  Card, CardContent, Tabs, Tab,
  Box,
  Pagination,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  CardActions,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';

import { getInvoices_external, getInvoice_external, updateOperationStatus_external } from '../services/invoice-service';
import { capitalizeText, computeSummary } from '../utils/etc';

dayjs.extend(utc);

const constructInvoiceTable = (invoice) => {
  return <table id="invoice-table">
    <tr>
      <td>{invoice.arrivalTimeConfirmed ? dayjs(invoice.arrivalTime).format('YYYY-MM-DD HH:mm') : 'TBC'} {capitalizeText(invoice.pickUp)}</td>
    </tr>
    <tr>
      <td>{invoice.departureTimeConfirmed ? dayjs(invoice.departureTime).format('YYYY-MM-DD HH:mm') : 'TBC'} {capitalizeText(invoice.dropOff)}</td>
    </tr>
    <tr>
      <td>{invoice.name}</td>
    </tr>
    <tr>
      <td>{invoice.contact}</td>
    </tr>
    <tr>
      <td>ID: {invoice.id}</td>
    </tr>
    <tr>
      <td>Car: {invoice.carNumbers ? invoice.carNumbers : 'N/A'}</td>
    </tr>
    <tr>
      <td>XQ Car: {invoice.xqCars ? invoice.xqCars : 'N/A'}</td>
    </tr>
    {invoice.InvoiceItems.map((i) => <tr key={i.id}>
      <td style={{ paddingTop: 10 }}>
        {i.booked && <PriceCheckOutlinedIcon size="small" />}<span style={{ verticalAlign: 'super' }}>{i.desc} ({i.quantity})</span>
      </td>
    </tr>)}
    <tr>
      <td>
        <br />
        Balance: {computeSummary(invoice.InvoiceItems, invoice.InvoicePayments).balance === 0 ? 'No Balance' : 'RM ' + computeSummary(invoice.InvoiceItems, invoice.InvoicePayments, invoice.rate).balance}
        {invoice.currency !== 'RM' ? ` / CNY ${computeSummary(invoice.InvoiceItems, invoice.InvoicePayments, invoice.currencyRate).balanceWithRate}` : ''}
        <br />
        {invoice.InvoicePayments.filter(p => p.status === 'pending').length > 0
          ? <span>Payment: RM {
            invoice.InvoicePayments.filter(p => p.status === 'pending')
              .reduce((sum, p) => sum + p.amount, 0)
              .toFixed(2)
          } (Waiting Approval)</span>
          : null
        }
      </td>
    </tr>
  </table>
}


const MyTasks = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    tab: 'new',
    data: {
      rows: [],
      count: 0
    },
    selectedInvoice: null,
    searchText: ''
  });
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    search(0);
  }, [state.tab]);

  const changeTab = (event, value) => {
    setState({ ...state, tab: value });
  };

  const updateOperationStatus = async (id, status) => {
    try {
      appContext.setLoading(true);
      await updateOperationStatus_external(id, status);
      appContext.showMessage('success', 'Invoice marked as completed successfully.');
      setState({
        ...state, data: {
          rows: state.data.rows.filter((r) => r.id !== id),
          count: state.data.count - 1,
        }
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  }

  const handlePrint = async (id) => {
    try {
      appContext.setLoading(true);
      const result = await getInvoice_external(id);
      const printWindow = window.open('', 'print', 'width=' + window.screen.availWidth + ',height=' + window.screen.availHeight + ',scrollbars=1,resizable=1');
      printWindow.document.open();
      printWindow.document.write(print(result, result.InvoiceItems, result.InvoicePayments, true));
      printWindow.document.close();
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  const handleView = async (id) => {
    try {
      appContext.setLoading(true);
      const result = await getInvoice_external(id);
      setViewData(result);
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  const search = async (page) => {
    try {
      appContext.setLoading(true);
      const result = await getInvoices_external(
        state.tab,
        state.searchText || '',
        page,
        configs.pageSize
      );
      setState({
        ...state, page, invoice: { id: 'New', open: false }, data: result
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  const handleCopy = (el) => {
    let body = document.body, range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }
    document.execCommand("Copy");
    document.getSelection().removeAllRanges();
    appContext.showMessage('success', 'Invoice copied.');
  }

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {(state.selectedInvoice && viewData) &&
            <Dialog maxWidth="lg" onClose={() => setState({ ...state, selectedInvoice: null })} open={state.selectedInvoice}>
              <DialogTitle>
                <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <Button
                      color="inherit"
                      onClick={() => {
                        const el = document.getElementById('invoice-table');
                        handleCopy(el);
                      }}
                    > Copy
                    </Button> |
                    <Button
                      color="inherit"
                      onClick={async () => {
                        await handlePrint(state.selectedInvoice);
                      }}
                    >
                      Print
                    </Button> |
                    <Button autoFocus onClick={() => { setState({ ...state, selectedInvoice: null }); setViewData(null) }} color="inherit">
                      Close
                    </Button>
                  </Toolbar>
                </AppBar>

              </DialogTitle>
              <DialogContent>
                {viewData && <>
                  {constructInvoiceTable(viewData)}
                </>}
              </DialogContent>
            </Dialog>
          }

          <Card>
            <CardContent>
              <Tabs
                value={state.tab}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                onChange={changeTab}
              >
                <Tab label="New" value="new" />
                <Tab label="In Progress" value="progress" />
                <Tab label="Completed" value="completed" />
              </Tabs>

              <Grid sx={{ paddingTop: 2 }} container spacing={1}>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    size="small"
                    placeholder="Search..."
                    variant="outlined"
                    value={state.searchText}
                    onChange={(e) => setState({ ...state, searchText: e.target.value })}
                  />
                  <Button
                    startIcon={<SearchIcon />}
                    onClick={() => search(0)}
                    style={{ marginLeft: 5 }}
                    variant="link"
                  >
                  </Button>
                </Grid>
                {state.data.rows.map((r) => (
                  <Grid item lg={6} md={6} xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {r.arrivalTimeConfirmed ? dayjs.utc(r.arrivalTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm') : 'TBC'} {capitalizeText(r.pickUp)} <br />
                          {r.departureTimeConfirmed ? dayjs.utc(r.departureTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm') : 'TBC'} {capitalizeText(r.dropOff)} <br />
                          {r.name} <br />
                          <a href={`tel:${r.contact}`}>{r.contact}</a> <br />
                          ID: {r.id} <br />
                          {r.pic} <br />
                          {r.source} <br />
                          {r.carNumbers && <span>{r.carNumbers} <br /></span>}
                          {r.xqCars && <span>{r.xqCars} <br /></span>}
                          {r.operatorNotes}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" onClick={async () => { await handleView(r.id); setState({ ...state, selectedInvoice: r.id }); }}>View</Button>
                        {state.tab === 'new' && <Button size="small" onClick={async () => await updateOperationStatus(r.id, 'progress')}>Move to In Progress</Button>}
                        {state.tab === 'progress' && <>
                          <Button size="small" onClick={async () => await updateOperationStatus(r.id, 'new')}>Move to New</Button>
                          <Button size="small" onClick={async () => await updateOperationStatus(r.id, 'completed')}>Move to Completed</Button>
                        </>}
                        {state.tab === 'completed' && <>
                          <Button size="small" onClick={async () => await updateOperationStatus(r.id, 'progress')}>Move to In Progress</Button>
                        </>}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Pagination onChange={(e, val) => search(val - 1)} sx={{ paddingTop: 2 }} count={state.data.count % configs.pageSize === 0 ? state.data.count / configs.pageSize : Math.floor(state.data.count / configs.pageSize) + 1} color="primary" />
              {/* <GridView search={search} columns={filteredColumns} data={state.data} SelectedDialog={null} allowAdd={false} selectedAction={viewInvoice} /> */}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default MyTasks;
